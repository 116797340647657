div.noticeBar {
    background: $alert;
    color: $light;
    display: block;
    width: 100%;
    padding: .5rem 0;
    text-align: center;

    &:before {
        font-family: $icon-font;
        @extend %icon-attention;
        margin-right: 1rem;
        font-size: rem(24px);
        font-weight: normal;
    }

    &.absolute {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index:100;
    }

    a {
        color:$light;
    }

}

noscript div:first-child {
    display: none;
}

.breadcrumb li {
    list-style: none;
    float: left;

    &:before {
        content: ">";
        padding: 0 1rem;
    }

    &:first-child:before {
        content: "";
        padding: 0;
    }

}

/**
 * Debug mode
 */
%debug {
    height: 100%;
    overflow-y: scroll;
    background: $light;
    padding: 1%;
}

#log_container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    color: $dark;
    background: darken($light, 5%);
    padding: 2%;
    transition: all 500ms ease-in-out;
    box-shadow: inset 0 0 rem(1px) rem(1px) rgba($alert, .5);
    z-index: 99999999;

    pre {
        @extend %debug;
    }

    .checkupXYZ {
        @extend %debug;
    }

    &:target {
        top: 0;
    }

    &.show {
        top: 0;
    }

    ul {
        list-style: inside square;
        ul {
            margin-left: 2rem;
        }

        h3 {
            display: inline-block;
        }

        li {
            padding: 0.5rem 0;
            border-bottom: rem(1px) solid darken($light, 5%);
            &:last-child {
                border: none;
            }
        }
    }

    h1 {
        text-align: center;
        padding-top: 2rem;
    }

    h2 {
        padding-bottom: 0.5rem;
        border-bottom: rem(1px) solid $medium;
        text-align: center;
        font-weight: normal;
        margin-top: 4rem;
    }

    h3 {
        font-weight: normal;
    }

    .checkup_pages {
        li {
            padding: 1rem;
        }

        .sub {
            margin-left: 2rem;
        }
    }

    > p {
        margin-bottom: 4rem;
    }

}

.checkup_pages > li:nth-child(odd) {
    background: #F4F4F4;
}


.checkupXYZ {
    width: 960px;
    margin: 0 auto;
    resize: horizontal;
}

.informatizer {
    background: #8DD45C;
    padding: 1%;
    position: fixed;
    top: 2%;
    right: 2%;
    display: block;
    color: $light;
    transition: all 500ms ease-in-out;
    text-decoration: none;

    &:before {
        font-family: $icon-font;
        @extend %icon-info;
        margin-right: 1rem;
        font-size: em(24px);
        line-height: 1rem;
    }

    a {
        color: $light;
    }

}

/**
 * Accordion
 */
.accHandler {
    cursor: pointer;

    &:after {
        @extend %icon-plus;
        display: inline-block;
        font-family: $icon-font;
        float: right;
        font-size: em(9px);
        transition: all 300ms ease-in-out;
    }

    &.active:after {
        @extend %icon-minus;
    }

}

/**
 * Back to top
 */
.backToTop {
    text-decoration: none;
    position: fixed;
    bottom: $base-gap;
    right: $base-gap;
    text-indent: -9999px;
    opacity: .9;
}
