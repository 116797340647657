// Styled lists
dl.floatList {
    dt, dd {
        display: inline-block;
        vertical-align: top;
    }

    dt {
        padding-right: .5rem;
        min-width: 40%;
    }

    dd {
        width: 60%;
    }
}

.base-gap{
    padding: 30px 0;
}

.unstyled {
    margin-bottom: 0;

    li {
        padding: 0;

        &:before {
            display: none;
        }
    }

    ul {
        margin: 0;
    }
}

.page-wrap, .header {
    transform: none;
    @extend %animated-transform;
}

.text-center{
    text-align: center;
    @include breakpoint(medium){
        text-align: left;
    }
}

.gap-top{
    padding-top: $base-gap*2;
}

.client-wording{
    white-space: nowrap;
    font-weight: 600;
    color: $strong-grey-darker;
    text-transform: uppercase;
}

// Header
header {
    background: $grey-darker url(/images/fliesenausstellung-bade-baustoffe-moenkebude-tiny.jpg) no-repeat center center / cover;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    transition: all 0.25s ease;
    padding: $base-gap*2 0;
    z-index: 0;
    @include breakpoint(small)
    {
        background: $grey-darker url(/images/fliesenausstellung-bade-baustoffe-moenkebude-small.jpg) no-repeat center center / cover;
    }
    @include breakpoint(medium)
    {
        margin-bottom: $base-gap*4;
        padding: 0;
        height: auto;
        background: $grey-darker url(/images/fliesenausstellung-bade-baustoffe-moenkebude-medium.jpg) no-repeat center center / cover;
    }
    @include breakpoint(large)
    {
        background: $grey-darker url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;
    }
    @include breakpoint(giant)
    {
        background: $grey-darker;
    }
    &.fullHeader{
        @include breakpoint(medium){
            min-height: calc(100vh - 174px);
        }
    }
    >.row{
        pointer-events:none;
    }
    .header-teaser{
        padding: $base-gap*4 $base-gap;
        margin: $base-gap*2 0;
        background: $white;
        z-index: 11;
        position: relative;
        pointer-events: auto;
        @include breakpoint(medium){
            padding: $base-gap*4;  
            margin: $base-gap*4 0;          
        }
        p{
            margin: 0;
            font-size: 20px;
            font-weight: 400;
        }
        a.btn{
            position: absolute;
            bottom: 0;
            right: $base-gap*4;
            transform: translateY(35%);
        }
    }
    .scroll-down{
        height: 45px;
        width: 45px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        display: block;
        opacity: 0;
        animation: 1s scrollDownOpacity forwards ease 5s;
        &:before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: $secondary;
            opacity: 1;
            transition: all 0.25s ease;
            animation: 2s scrollDown infinite ease;
        }
        &:hover{
            &:before{
                animation: none;
            }
        }
    }
    .slideshow{
        height: 100%;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: -1;
        
        hr{
            &.sliderHr{
                width: 100%;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                max-width: none;
                display: block;
                margin: 0;

                &.slider1{
                    background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;

                    @include breakpoint(medium) {
                        background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;
                    }
                }

                &.slider2{
                    background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;

                    @include breakpoint(medium) {
                        background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;
                    }
                }

                &.slider3{
                    background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;

                    @include breakpoint(medium) {
                        background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;
                    }
                }

                &.slider4{
                    background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;

                    @include breakpoint(medium) {
                        background: url(/images/fliesenausstellung-bade-baustoffe-moenkebude.jpg) no-repeat center center / cover;
                    }
                }
            }
        }

        .showslideController{
            &.showslideNext,
            &.showslidePrev{
                display: none;
            }
        }
    }
}

// .branding {
//     display: block;
//     width: 100%;
//     height: 60px;
//     img{
//         height: 100%;
//         width: auto;
//     }
// }

.branding-header{
    position: relative;
    margin-bottom: $base-gap*2;
    display: block;
    width: 185px;
    @include breakpoint(large){
        display: none;
    }
    img{
        width: 100%;
    }
}

main{
    overflow: hidden;
    .responsive-img{
        height: auto;
        max-height: 500px;
        width: 100%;
        overflow: hidden;
        position: relative;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(255,255,255,0);
            position: relative;
            width: 100%;
            height: 200px;
            background: none;
            font-size: 22px;
            @include breakpoint(medium){
                height: 400px;
            }
            &:before{
                content: "";
                display: block;
                height:30px;
                width: 30px;
                border-left: 2px solid $light;
                border-top: 2px solid $light;
                position: absolute;
                left: 15px;
                top: 15px;
                pointer-events: none;
                transition: all 0.25s ease;
            }
            &:after{
                content: "";
                display: block;
                height: 30px;
                width: 30px;
                border-right: 2px solid $light;
                border-bottom: 2px solid $light;
                position: absolute;
                right: 15px;
                bottom: 15px;
                pointer-events: none;
                transition: all 0.25s ease;
            }
            &:hover{
                color: rgba(255,255,255,1);
                background: rgba(0,0,0,0.65);
                &:before{
                    width: calc(100% - 32px);
                    height: calc(100% - 32px);
                }
                &:after{
                    width: calc(100% - 32px);
                    height: calc(100% - 32px);
                }
            }
        }
    }
    .teaser{
        overflow: hidden;
        padding: $base-gap*3 0;
        .teaser-bg{
            z-index: 0;
            position: relative;
            padding-top: $base-gap*4;
            padding-bottom: $base-gap*4;
            &:after{
                content: "";
                width: 100vw;
                height: 100%;
                position: absolute;
                background: $secondary;
                right: 0;
                top: 0;
                z-index: -1;
            }
            p{
                margin-bottom: 0;
            }
        }
        &.right{
            >.row{
                flex-wrap: wrap-reverse;
                @include breakpoint(small){
                    flex-wrap: wrap;
                }
            }
            .teaser-bg{
                &:after{
                    right: auto;
                    left: 0;
                }
            }
            a{
                right: auto;
                left: $base-gap*4;
            }
        }    
        a{
            position: absolute;
            bottom: 0;
            right: $base-gap*4;
            transform: translateY(35%);
        }
        .teaser-img{
            width: calc(100% + #{$base-gap*2});
            margin-left: -$base-gap;
            position: relative;
            overflow: hidden;
            min-height: 150px;
            @include breakpoint(small){                
                min-height: 250px;
                height: 100%;
            }
            img,iframe{
                position: absolute;
                top: 0;
                left: 0;
                min-width: 100%;
                width: auto;
                max-width: none;
                height: auto;
                min-height: 100%;
                max-height: none;
            }
        }
    }
}
.kontakt-area{
    margin: $base-gap*3 0;
    h2{
        color: $secondary;
    }
    p{
        color: $light;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .kontakt-bg-1{
        position: relative;
        z-index: 0;
        padding-top: $base-gap*4;
        padding-bottom: $base-gap*4;
        background: $dark;
        @include breakpoint(medium){
            background: none;
        }
        &:after{
            @include breakpoint(medium){
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 50vw;
                background: $dark;
                z-index: -1;
            }
        }
    }
    .kontakt-bg-2{
        position: relative;
        z-index: 0;
        padding-top: $base-gap*4;
        padding-bottom: $base-gap*4;
        background: $secondary url(/images/bade-baustoffe-kontakt.jpg) no-repeat center center / cover;
        @include breakpoint(medium){
            background: none;
        }
        &:after{
            @include breakpoint(medium){
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 50vw;
                background: $secondary url(/images/bade-baustoffe-kontakt.jpg) no-repeat center center / cover;
                z-index: -1; 
            }
        }
        .kontakt-list{            
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;
        }
        .btn{
            margin: $base-gap/2 0;
            i{
                margin-right: $base-gap;
            }
        }
    }
}

.section{
    padding-top: $base-gap*2;
    padding-bottom: $base-gap*2;
    @include breakpoint(medium){
        padding-top: $base-gap*3;
        padding-bottom: $base-gap*3;
    }
}

footer{
    background: $secondary;
    overflow: hidden;
    margin-top: $base-gap*3;
    padding: $base-gap*2 0;
    @include breakpoint(medium){
        padding: 0;
    }
    .contact-data{
        padding-top: $base-gap*6;
        padding-bottom: $base-gap*6;
        p{
            color: $light;
            &:last-child{
                margin-bottom: 0;
            }
            i{
                width: 20px;
                margin: 0 $base-gap 0 0;
            }
        }
    }
}

// Misc
.highlight {
    color: $secondary;
}

.googleMaps {
    border: none;
    height: em(350px);
    margin-bottom: 1.5rem;
    width: 100%;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

.fancy {
    position: relative;
    text-decoration: none;

    &:before {
        content: "";
        position: absolute;
        width: 0;
        top: 100%;
        left: 0;
        height: 1px;
        background: $primary;
        transition: 300ms all ease-in-out;
    }

    &:hover:before {
        width: 100%;
    }
}

.parallax{
    overflow:hidden;
    position:relative;
    .parallaxImg{
        height:auto;
        width:100%;
        position:absolute;
        left:50%;
        z-index:-1;
        transform:translate(-50%, 0);
    }
}

@keyframes scrollDown{
    0% {transform:translate(-50%, -100%); opacity: 0;}
    50% {transform:translate(-50%, -50%); opacity: 1;}
    100% {transform:translate(-50%, 100%); opacity: 1;}
}

@keyframes scrollDownOpacity{
    to {opacity: 1;}
}