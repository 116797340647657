table{
	display: inline-block;
	vertical-align: top;
	width: 100%;
	overflow-x: auto;
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid $grey-light;
	-webkit-overflow-scrolling: touch;
	
	// optional - looks better for small cell values
	white-space: nowrap;

	th,
	td {
		padding:rem(10px) rem(58px) rem(10px) 1rem;
		border:none;
		width: 1%;
	}
	th {
		text-align: left;
		background: $soft-grey-dark;
		padding: rem(6px) rem(58px) rem(6px) 1rem;
	}
	tbody tr:not(:first-child) {
		border-top:1px solid $grey-lighter;
	}
	thead tr {
		border-bottom:1px solid $grey-light;
	}

	&.table-strip {
		tr:nth-child(odd) {
			background: $soft-grey-lighter;
		}
	}

	&.table-boxed {
		td:not(:last-child)
		{
			border-right:1px solid $grey-lighter;
		}
	}

	&.table-open {
		border:none;

		th {
			background: transparent;

			&:not(:last-child) {
				border-right:1px solid $grey-lighter;
			}
		}
	}

	a
	{
		text-decoration: none;
	}
}