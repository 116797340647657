/**
 * Icons
 */
%iconfont-reset {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon {
    font-family: $icon-font;
    @extend %iconfont-reset;
}

.icon-plus:before {
    @extend %icon-plus;
}
.icon-play:before {
    @extend %icon-play;
}
.icon-pause:before {
    @extend %icon-pause;
}
.icon-attention:before {
    @extend %icon-attention;
}
.icon-minus:before {
    @extend %icon-minus;
}
.icon-info:before {
    @extend %icon-info;
}
.icon-file-pdf:before {
    @extend %icon-file-pdf;
}
.icon-menu:before {
    @extend %icon-menu;
}
.icon-ok:before {
    @extend %icon-ok;
}
.icon-facebook:before {
    @extend %icon-facebook;
}
.icon-cancel:before {
    @extend %icon-cancel;
}
.icon-up:before {
    @extend %icon-up;
}
.icon-right:before {
    @extend %icon-right;
}
.icon-left:before {
    @extend %icon-left;
}
.icon-down:before {
    @extend %icon-down;
}
.icon-twitter:before {
    @extend %icon-twitter;
}
.icon-gplus:before {
    @extend %icon-gplus;
}
.icon-phone:before {
    @extend %icon-phone;
}
.icon-mail:before {
    @extend %icon-mail;
}
.icon-star:before {
    @extend %icon-star;
}
.icon-star-empty:before {
    @extend %icon-star-empty;
}
.icon-star-half:before {
    @extend %icon-star-half;
}
.icon-download:before {
    @extend %icon-download;
}
.icon-clock:before {
    @extend %icon-clock;
}


// Placeholders
%icon-plus {
    content: '\e800';
}
%icon-play {
    content: '\e801';
}
%icon-pause {
    content: '\e802';
}
%icon-attention {
    content: '\e803';
}
%icon-minus {
    content: '\e804';
}
%icon-info {
    content: '\e805';
}
%icon-file-pdf {
    content: '\e806';
}
%icon-menu {
    content: '\e807';
}
%icon-ok {
    content: '\e808';
}
%icon-facebook {
    content: '\e809';
}
%icon-cancel {
    content: '\e80d';
}
%icon-up {
    content: '\e80b';
}
%icon-right {
    content: '\e817';
}
%icon-left {
    content: '\e816';
}
%icon-down {
    content: '\e80c';
}
%icon-twitter {
    content: '\e80f';
}
%icon-gplus {
    content: '\e810';
}
%icon-phone {
    content: '\e811';
}
%icon-mail {
    content: '\e812';
}
%icon-star {
    content: '\e80a';
}
%icon-star-empty {
    content: '\e80e';
}
%icon-star-half {
    content: '\e814';
}
%icon-download {
    content: '\e815';
}
%icon-clock {
    content: '\e813';
}
