/**
 * jQuery content slideshow
 * @name showslide
 * @author Dominik Kressler
 * @version 1.1.3
 * @category jQuery plugin
 * @copyright (c) 2013
 *
 * Changelog:
 *
 * SEE JS FILE
*/


/**
 * NAVIGATION
 */
.showslideController {
    cursor: pointer;
    list-style: none;
    z-index: 20;
    transition: all 500ms ease-in-out;
}

a.showslideController {
    background-color: rgba($dark, .2);
    border-radius: rem(4px);
    display: block;
    height: rem(30px);
    position: absolute;
    @include hide-text();
    padding:rem(3px);
    opacity: .6;
    width: rem(30px);

    &.showslidePlay {
        bottom: rem($base-gap);
        left: 50%;
        margin-left: rem(-15px);

        &:before {
            @extend %icon-pause;
            @extend %controls-pseudo;
        }

        &.true:before {
            @extend %icon-play;
            @extend %controls-pseudo;
        }
    }

    &.showslidePrev, &.showslideNext {
        top: 50%;
        margin-top: rem(-15px);
    }

    &.showslidePrev {
        left: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend %icon-left;
        }
    }

    &.showslideNext {
        right: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend %icon-right;
        }
    }

    &:hover {
        background-color: rgba($dark, .8);
        opacity:1;
    }
}

.showslideSelect {
    z-index: 20;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    margin:auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(to left,  rgba($white,0.1) 0%,rgba($white,0) 100%);

    li {
        display: inline-block;
        @include hide-text();
        width: 50px;
        height: 22px;
        padding: $base-gap/2 0;
        transition: all 0.25s ease;
        opacity: 0.6;
        font-size: 0;
        &:before{
            content:"";
            display: block;
            width: 100%;
            height: 6px;
            background: $strong-grey-darker;
        }
    }
    &.thumbnails li {
        width: rem(64px);
        height: rem(64px);
        background-size: cover;
        border: 3px solid $primary;
    }
    li {
        &.active, &:hover {
            width: 80px;
            opacity: 1;
        }
    }
}

/**
* GLOBALS
**/

.slider {
    display: none;
    @include breakpoint(giant){
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    /**
     * SPEED SETTING
     */

    > .animate {
        transition-duration: 1000ms;
    }

    /**
    * SPECIAL EASINGS
    * FOR MORE EASINGS: http://matthewlein.com/ceaser/
    **/
    &.horizontal > .animate {
        transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
        /* easeInOutQuad */
    }
    &.vertical > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.fade > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.flip > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffOut > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffIn > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.zoomOut > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.zoomIn > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }

    .slide {
        z-index: 1;

        &:not(.active) {
            z-index: 1;
        }
        &.active {
            float: left;
            position: relative;
            z-index: 10;
        }

        img {
            height:auto;
            width:100%;
        }

        width: 100%;
        height: 100%;
        display: block;
        position: absolute;

        /**
        * ELEMENTS WITH data-caption-ATTRIBUTES
        **/
        &[data-caption]:after {
            content: attr(data-caption);
            background: rgba(255, 255, 255, 0.5);
            left: 0;
            color: #000;
            display: block;
            padding: 1em;
            position: absolute;
            width: 100%;
            top: -100%;
        }
        &.active[data-caption]:after {
            top: 0;
        }
        &[data-caption]:after {
            transition-property: all;
        }
    }

    /**
    * TRANSITIONS
    **/
    &.fade > .animate, &.horizontal > .animate, &.vertical > .animate, &.zoomOut > .animate, &.zoomIn > .animate, &.puffOut > .animate, &.puffIn > .animate {
        transition-property: all;
    }

    /**
    * FADE
    **/
    &.fade .slide {
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }

    /**
    * HORIZONTAL
    **/
    &.horizontal .slide {
        &.active {
            left: 0;
            z-index: 10;
        }
        &.right {
            left: 100%;
            z-index: 1;
        }
        &.left {
            left: -100%;
            z-index: 1;
        }
    }

    /**
     * VERTICAL
     */
    &.vertical {
        .active {
            top: 0;
            z-index: 10;
        }
        .left {
            top: -100%;
            z-index: 1;
        }
        .right {
            top: 100%;
            z-index: 1;
        }
    }

    /**
     * FLIP
     */
    &.flip {
        > .animate {
            transition: all 2000ms ease-in-out;
        }
        overflow: visible;
        .left {
            opacity: 0;
            transform: perspective(800px) rotateX(-180deg) translateZ(300px);
        }
        .right {
            opacity: 0;
            transform: perspective(800px) rotateX(180deg) translateZ(300px);
        }
        .active {
            opacity: 1;
            transform: perspective(0) rotateX(0) translateZ(0);
        }
    }

    /**
     * puffOUT
     */
    &.puffOut {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(0.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * puffIN
     */
    &.puffIn {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(0.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomOUT
     */
    &.zoomOut {
        .left {
            opacity: 0;
            transform: scale(0.2);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomIN
     */
    &.zoomIn {
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }
}
