.clear {
    clear: both;
    float: none;
}

.clearfix {
    @include pie-clearfix;
}

.hidden {
    display: none;
}

.hide-text {
    display: inline-block;
    @include hide-text();
    overflow: hidden;
    width:0;
}

.floatLeft {
    float: left;
}

img.floatLeft {
    margin-right:1rem;
}

.floatRight {
    float: right;
}

img.floatRight {
    margin-left:1rem;
}

.fluid {
    height:auto;
    width:100%;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.wide {
    width: 100%;
    display: block;
}
