/**
 * Fonts
 */

// Icons
@include font-face('datedropper',
    'datedropper.woff',
    'datedropper.ttf',
    'datedropper.svg',
    'datedropper.eot'
);

@font-face {
    font-family: 'Muli';
    src: url('/fonts/muli-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/fonts/muli-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/fonts/muli-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Muli';
    src: url('/fonts/muli-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('/fonts/muli-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}