

// Colorbox buttons
$cbox-button-height:25px;
$cbox-button-width:25px;

%cbox-button {
    background: $primary;
    height: rem($cbox-button-height);
    width: rem($cbox-button-width);
    position: absolute;
    @include hide-text();
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
        background: $secondary;
    }
}

%cbox-button-before {
    @extend .icon;
    color: $light;
    font-size: em(16px);
    height: 100%;
    width: 100%;
    position: absolute;
    text-indent: 0;
    @extend %cbox-transition;
    left: 0;
    top:50%;
    margin-top:rem(-6px);
}


%cbox-transition {
    transition: 500ms;
}

%cbox-wrappers {
    box-sizing: content-box;
}


// Styles for slideshow pseudo elements
%controls-pseudo {
    @extend .icon;
    text-indent: 0;
    display: block;
    color: $light;
    font-size: rem(24px);
    text-align: center;
    line-height: 2rem;
}


// Optimize text kerning
%optimized-rendering {
    text-rendering: optimizeLegibility;
}

// Animations
%animated-transform {
    transition: 300ms;
}
