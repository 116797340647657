/*=NAVIGATION MAIN */
.navi-main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin-bottom: 0;
    width: 100%;
    @include breakpoint(large){        
        flex-direction: row;
        // height: 4.25rem; 
    }
    li {
        display:inline-block;
        position:relative;
        padding: 0;
        transition: all 0.25s ease;
        &.startseite,&.impressum, &.datenschutzerklaerung{
            display: block;
            @include breakpoint(large){
                display: none;
            }
        }        
        &.facebook{
            background: url(/images/facebook.png) no-repeat center center;
            @include breakpoint(large){
                background: url(/images/facebook.png) no-repeat center 63%;
            }
            a{
                color: #3b579d;
                font-weight: 600;
                @include breakpoint(large){
                    font-size:0;
                }
            }            
        }
        &:hover {
            @include breakpoint(large){
                >a, >span {
                    color: $strong-grey-darker;
                    background: $secondary;
                }
                >.sub{
                    height:100%;
                    overflow:visible;
                    transition: all 0s ease;
                    >li{
                        transform: translateX(0);
                        opacity:1;
                        &:nth-child(1){
                             transition-delay: 0.0s;
                        }
                        &:nth-child(2){
                             transition-delay: 0.1s;
                        }
                        &:nth-child(3){
                             transition-delay: 0.2s;
                        }
                        &:nth-child(4){
                             transition-delay: 0.3s;
                        }
                        &:nth-child(5){
                             transition-delay: 0.4s;
                        }
                        &:nth-child(6){
                             transition-delay: 0.5s;
                        }
                        &:nth-child(7){
                             transition-delay: 0.6s;
                        }
                        &:nth-child(8){
                             transition-delay: 0.7s;
                        }
                        &:nth-child(9){
                             transition-delay: 0.8s;
                        }
                        &:nth-child(10){
                             transition-delay: 0.9s;
                        }
                        &:nth-child(11){
                             transition-delay: 1s;
                        }
                        &:nth-child(12){
                             transition-delay: 1.1s;
                        }
                        &:nth-child(13){
                             transition-delay: 1.2s;
                        }
                    }
                }
            }
        }
        
        &.active {        
            a {
                background:$secondary;  
                color: $strong-grey-darker;
            }
        }

        a,span {
            background:$light;            
            cursor:pointer;
            text-align:left;
            text-decoration:none;
            text-transform: uppercase;
            width: 100%;
            font-weight:400;
            display: flex;
            align-items: center;
            height: rem(60px);
            font-size:1rem;
            position:relative;
            font-family: $display-font;
            color: $dark;
            padding:0 1rem;
            transition: all 0.25s ease;
            @include breakpoint(large)
            {   
                height: rem(90px);
                text-align:center; 
                padding:0 $base-gap;    
            }
            @include breakpoint(giant)
            {
                padding:0 $base-gap*2;
                font-size: 1.125rem;
            }
        }
        span{
            cursor:default;
        }
        // last child of navigation with sub
        &:last-child{
            .sub {
                right:0;
                left:auto;
                transform: translateX(0);                
                @include breakpoint(giant){
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        } 
    } 
    .sub {
        display: block;
        overflow:visible;
        position:relative;
        white-space: nowrap;
        height: auto;
        min-width:100%;
        left:50%;
        transform: translateX(-50%);
        transition: height 0.25s ease;

        @include breakpoint(large){
            overflow:hidden;
            height:0px;
            position:absolute;
            margin: 0;
        }
        &.active{
            flex-wrap:wrap-revers;
        }

        >li{ 
            display: block;
            @include breakpoint(large){                
                opacity: 0;
                transition: all 0.25s ease;
                transform: translateY(20%);
                &:nth-child(2n){ 
                    // transform: translateY(20%);   
                }
            }
            &:after{
                display: none;
            }
            a {
                height: 3rem;
                color:$light;
                padding:0 1.5rem;
                cursor:pointer;
                border-left: 2px dotted $medium;
                @include breakpoint(large){
                    border: none;
                    color: $dark;
                    background:$secondary;
                }
                &:hover{                    
                    border-left: 2px dotted $primary;
                    @include breakpoint(large){ 
                        border: none;
                        background:$primary;
                        color:$light;
                    }  
                }
                &.active{
                    border-left: 2px dotted $light;
                    color:$light;
                    background:$primary;
                    @include breakpoint(large){
                        border: none;                        
                    }
                }       
            }
        }
        
        .sub{
            @include breakpoint(large){
                left:100%;
                transform: none;
                top:0;                
            }
        }
    }
}
.noSub{
    li{
        .sub{
            display: none;
        }
    }
}

// Off-canvas navigation
.page-wrap{
    margin-top:50px;
    @include breakpoint(large){
        margin-top: rem(144px);
    }
}
.page-navi {
    background: $light;
    min-height: 100vh;
    transform: translateY(-100%);
    @extend %animated-transform;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index:100;
    @include breakpoint(large) {
        transform: translateY(-55px);
        transition: none;
        position: fixed;
        min-height: inherit;
        transition: all 0.5s ease;
        border-bottom: 1px solid $secondary;
        // padding: 20px 0;
        &.active{
            transform: none;
        }
    }    
    .nav-pre{
        display: none;
        @include breakpoint(large){            
            display: flex;
            width: 100%;
            padding: $base-gap 0;
            background: $secondary;
        }
        .nav-pre-btn{
            display: flex;
            justify-content: center;
            align-items: baseline;
            padding: 0 $base-gap;
            a{
                color: $strong-grey-darker;
                &:hover{
                    color: $primary;
                }
            }
            i{
                margin-right: $base-gap/2;
            }
        }
    }
    .nav-flex{
        display: flex;
        align-items: stretch;
        flex-flow: column nowrap;
        justify-content: space-between;
        @include breakpoint(large){
            flex-flow: row nowrap;
            align-items: center;
        }
    }
    .brandingMenu{
        display: inline-block;
        text-align:center;
        background:$secondary;
        padding:0.5rem 0;
        width: 100%;
        min-width: 185px;
        margin-bottom: $base-gap;
        @include breakpoint(large){
            background: none;
            margin-right: $base-gap*4;
            padding: 0;
            width: 185px;
            margin-bottom: 0;
        }
        img{
            width: 185px;
            height: auto;
            @include breakpoint(large){
                // width: 100%;
            }
        }
    }
    .ctaNav{
        width: 100%;
        position:relative;
        float: left;
        @include breakpoint(large){
            // display: none;
        }
        a, .mobileButton{
            display: block;
            color:$light;
            background:$primary;
            padding:10px 0;
            height: 45px;
            width:100%;
            text-align:center;
            margin-bottom: 5px;
            @include breakpoint(large) {
                display: none;
            }
            i{
                font-size:1.625rem;
            }
            p{
                font-size:1rem;
                margin:-5px 0 0 15px;
                display: inline-block;
                color:$light;
                transform: translateY(-4px);
            }
        }
    }
}
.iexplore {
    .nav-add {
        display: none;
        @include breakpoint(medium){
            display:block;
            font-weight:400;      
            height:auto;
            width: 500px;
            margin: 0 auto;
        }
        li{
            display:inline-block;
        }
    }
}
.nav-add{
    display: none;
    @include breakpoint(medium){
        display:flex;
        justify-content:flex-end;
        font-weight:400;
        flex-direction: column;
        margin: 0 auto;
        padding: 1rem 0;
        flex-direction:row;
        justify-content: flex-start;
    }
    @include breakpoint(giant){
        justify-content: flex-end;
    }
    li{
        display: inline-block;
        a{
            color:$strong-grey-darker;
            font-weight: 400;
            font-size: 14px;
            float:left; 
            text-transform: uppercase;
            clear: both;
            padding: 0 1rem;
            transition: all 0.25s ease;
            @include breakpoint(large){
                font-size: 1rem;
            }
            @include breakpoint(giant){                
                font-size: 1rem;
            }
            &:hover,&.active{
                color:$strong-grey;
            }
        }
    }
}

// Mobile Navi

.iconMenu {
    float: left;
    height: 25px;
    // top: 0.875rem;
    position: relative;
    width: 100%;
    z-index: 1000;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    transition: all 0.25s ease;
    // margin-bottom: 1rem;
    height:50px;
    padding: 0.75rem 0;
    position: fixed;
    top: 0;
    background: $light;
    @include breakpoint(large) {
        display: none;
    }

    span{        
        display: block;
        height:3px;
        width:30px;
        background: $medium;
        position:relative;
        opacity:1;
        transition: all 0.25s ease;
    }

    &:before {
        content:"";
        display: block;
        height:3px;
        width:30px;        
        background: $medium;
        position:relative;
        transform: rotate(0);
        transition: all 0.25s ease;
    }

    &:after {
        content:"";
        display: block;
        height:3px;
        width:30px;        
        background: $medium;
        position:relative;
        transform: rotate(0) translate(0);
        transition: all 0.25s ease;        
    }
    &:hover{
        &:before{
            background: $medium;
        }
        &:after{
            background: $medium;
        }
        span{
            background: $medium;
        }
    }
} 
#nav-checkbox{
    display: none;
    &:checked{
        ~.page-navi {
            transform: translateY(0);
            top:50px;
        }
        ~.page-wrap {
            transform: translateX(0%);
            position: fixed;
            width:100%;    
            z-index:-1;   
        }
        ~.iconMenu{
            width: 100%;
            background:$light;
            left:0;
            top:0;
            justify-content:center;
            span{
                opacity:0;
                background: $primary;
            }
            &:before {
                transform: rotate(45deg);
                background: $primary;
            }
            &:after {
                transform: rotate(-45deg) translate(4px, -4px);
                background: $primary;
            }
        }

        @include breakpoint(large) {
            ~.page-navi {
                transform: translateY(0);
                top:0;
            }
            ~.page-wrap {
                transform: translateX(0);
                position: relative;
            }
        }        
    }
}
