.cms-user{
    .cms_nav{
        position: fixed;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        display: flex;
        z-index: 1100;
        background: $white;
        box-shadow: 0 0 6px -2px rgba($black, 0.8);
        padding: $base-gap/3;
        @include breakpoint(large){   
            bottom: auto;         
            top: 0;
            right: $base-gap;
            transform: none;
        }
        .btn{
            &.icon-only{
                &:hover{
                    .information{
                        transform: none;
                        opacity: 1;
                    }
                }
                .information{
                    bottom: calc(100% + 10px);
                    top: auto;
                    right: 0;
                    @include breakpoint(large){
                        bottom: auto;
                        top: calc(100% + 10px);
                    }
                    &:after{
                        border-top: 5px solid $strong-grey-lighter;
                        border-bottom: 5px solid transparent;
                        top: 100%;
                        @include breakpoint(large){
                            top: auto;
                            bottom: 100%;                            
                            border-bottom: 5px solid $strong-grey-lighter;
                            border-top: 5px solid transparent;
                        }
                    }
                }
            }
        }
    }
    form{
        &.cms-content{
            float: none;
            position: relative;
            z-index: 1;
            overflow: visible;
            transition: all 0.25s ease;
            margin-top: $base-gap;
            margin-bottom: $base-gap;
            align-self: flex-start;
            input, textarea{
                padding: $base-gap;
                // margin: 0;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            textarea{
                min-height: 150px;
            }
            .cms-btn{
                display: flex;           
            }
            .cms-nav{
                position: relative;
                width: calc(100% + #{$base-gap*2});
                margin-left: -$base-gap;
                margin-bottom: 0;
                margin-top: $base-gap;
                background: $strong-grey-dark;
                padding: $base-gap $base-gap;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .cms-btn{
                    .cms-btn-edit, .cms-btn-save, .cms-btn-delete, .cms-btn-close{
                        margin-bottom: 0;
                    }   
                }                
                .cms-tag{
                    text-transform: uppercase;
                    font-size: 14px;
                    color: $white;
                }
            }
            &:after{
                content:"";
                position: absolute;
                left: -$base-gap;
                top: -$base-gap;
                z-index: -1;
                height: calc(100% + #{$base-gap});
                width: calc(100% + #{$base-gap*2});
                background: none;
                box-shadow: 0 0 5px $black;
                opacity: 0.2;
                pointer-events: none;
                transition: all 0.25s ease;

            }
            &:hover{
                z-index: 10000;
                &:after{
                    opacity: 1;
                }
            }
        }
    }
    .cms-menu-container{
        padding: $base-gap;
        margin-bottom: $base-gap;
        width: 100%;
        background: $strong-grey-darker;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: flex-start;
        position: relative;
        z-index: 90;
        .area-tag-names{
            text-transform: uppercase;
            font-size: 14px;
            color: $white;
        }
    }
    .cms-menu-btn{
        position: relative;
        label{
            z-index: 1;
            position: relative;
            margin: 0;
            .open-option-icon{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                transform: none;
                transition: all 0.25s ease;
                span{
                    display: block;
                    margin: 1px;
                    width: 3px;
                    height: 3px;
                    background: $strong-grey;
                    transition: all 0.25s ease;
                }
            }
            &:hover{
                .open-option-icon{
                    span{
                        background: $strong-grey-darker;
                    }
                }
            }
        }
        input{
            &.open-option{
                display: none;
                &:checked{
                    ~.open-option{
                        .open-option-icon{
                            transform: rotate(-90deg);
                            span{
                                margin: 0;
                            }
                        }
                    }
                    ~.btn-container{
                        a{
                            opacity: 1;
                            pointer-events: auto;
                            transform: translateX(-15px);
                            &:nth-child(1){
                                // transform: translate(-100%, -115%);
                                transition: background ease-in .1s, transform 0.25s ease 0.2s, opacity 0.25s ease 0.2s;
                            }
                            &:nth-child(2){
                                // transform: translate(calc(-100% - 15px), 0);
                                transition: background ease-in .1s, transform 0.25s ease 0.1s, opacity 0.25s ease 0.1s;
                            }
                            &:nth-child(3){
                                // transform: translate(-100%, 115%);
                                transition: background ease-in .1s, transform 0.25s ease 0s, opacity 0.25s ease 0s;
                            }
                        }
                    }
                }
                ~.btn-container{
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: calc(100% + #{$base-gap});
                    transform: translate(0, -50%);
                    // width: 34px;
                    // height: 34px;
                    pointer-events: none;
                    a{
                        // position: absolute;
                        // right: calc(100% + #{$base-gap}); 
                        // top: 0;
                        opacity: 0;
                        pointer-events: none;
                        transform: none;
                        margin-left: 0;
                        margin-right: 0;
                        transform: none;

                        &:last-child{
                            margin: 0;
                        }

                        &:nth-child(1){
                            transition: background ease-in .1s, transform 0.25s ease 0s, opacity 0.25s ease 0s;
                        }
                        &:nth-child(2){
                            transition: background ease-in .1s, transform 0.25s ease 0.1s, opacity 0.25s ease 0.1s;
                        }
                        &:nth-child(3){
                            transition: background ease-in .1s, transform 0.25s ease 0.2s, opacity 0.25s ease 0.2s;
                        }
                    }
                }
            }
        }

        input{
            &.open-option{
                display: none;
            }
        }
    }

    .cms_input_upload{
        max-height: 300px;
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        .cms_upload_img,.cms_upload_file{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
            opacity: 0;
        }
        .cms_thumbnail_file{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $strong-grey-darker;
            background: $light;

            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% + #{$base-gap}));
                z-index: 2;
                opacity: 0;
                transition: all 0.25s ease;
                font-size: 20px;
                color: $strong-grey-darker;
                background: linear-gradient(to top, rgba($light,1) 0%,rgba($light,0) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
        .cms_thumbnail_img{
            height: auto;
            min-height: 60px;
            max-height: 300px;
            width: 100%;
            position: relative;
            left: 0;
            top: 0;
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                opacity: 1;
                transition: all 0.25s ease;
                z-index: 0;
            }
            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% + #{$base-gap}));
                z-index: 2;
                opacity: 0;
                transition: all 0.25s ease;
                font-size: 40px;
                color: $strong-grey-darker;
                background: linear-gradient(to top, rgba($light,1) 0%,rgba($light,0) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
        &:hover{
            .cms_thumbnail_img,.cms_thumbnail_file{
                i{
                    opacity: 1;
                    transform: translate(-50%, -50%);
                }
            }
        }
        #new_cms_thumbnail{
            display: flex;
            pointer-events: none;
            position: relative;
            z-index: 1;
            width: 100%;
            >span{
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                position: relative;
                pointer-events: auto;
                img{
                    width: auto;
                    height: auto;
                    max-height: 100%;
                    max-width: 100%;
                }               
                >.cancel-upload{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    border: none;
                    background: none;
                }
                .progress-bar{
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.25s ease 0.25s;
                    .pf-circle{
                        stroke-dasharray: 274.085;
                        stroke-dashoffset: 274.085;
                        transition: fill 0.25s ease 0.5s, stroke 0.25s ease;
                        stroke: $grey;
                        fill: transparent;
                    }
                    &.loading{
                        .pf-circle{
                            stroke-dashoffset: 274.085;
                            animation: uploadFileDash 2s linear forwards infinite;
                        }
                    }
                    &.loaded{
                        height: 15px;
                        width: 15px;
                        right: 15px;
                        top: 15px;
                        .pf-circle{                 
                            stroke-dashoffset: 0;
                            stroke: $success;
                            fill: $success;
                        }
                    }
                }
            }
        }
    }
    ul{
        &.cms_sitemap{
            li{
                &:before,&:after{
                    display: none;
                }
            }
        }
    }
}

@keyframes uploadFileDash {
    0% { stroke-dashoffset: 274.085; } 
    100% { stroke-dashoffset: -274.085; } 
}