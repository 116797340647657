// +++++ Module imports

// Import modules
@import 'modules/utilities';
@import 'modules/typography';
@import 'modules/responsive';
// @import 'modules/custom';

// *** Uncomment this @import if you want to use sprites.
// *** Be sure to copy your sprites into images/sprites and run "grunt sprite" once.
// @import "modules/sprites";

// Relativ path to images folder (used in the image-url() function)
$image-url-path: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$base-font-size: 16px;

// Line height of main content (in px)
$base-line-height: 24px;

// Headline font sizes (in px)
$h1-size: 35px;
$h2-size: 30px;
$h3-size: 24px;
$h4-size: 22px;
$h5-size: 18px;
$h6-size: 16px;

// Font stacks: $main-font for simple content, $display-font for headlines etc., $icon-font for icons
$main-font:    "Open Sans", sans-serif;
$display-font: "Muli", sans-serif;
$icon-font:    "Font Awesome 5 Free";


// +++++ Design & Layout
// Colours for your design.
$primary:   #e30613;
$primary-hover: darken($primary, 5%);
$secondary: #ececec;
$light:     #fff;
$medium:    #ccc;
$dark:      #1d1d1b;
$border:    #bbb;
$alert:     #D85D00;

// Signal colors
$success: #78c674;
$success-hover: #6db369;
$info: #ebb548;
$info-hover: #daa844;
$warning: #e74c3c;
$warning-hover: #cf4536;
$marked: #f7f3db;

// Basic white

$white: #ffffff;
$black: #000000;

// Grey tones

	//-- soft grey tones
	$soft-grey-lighter: #fcfcfc;
	$soft-grey-light: #fafafa; 
	$soft-grey: #f8f8f8; 
	$soft-grey-dark: #f5f5f5; 
	$soft-grey-darker: #f1f1f1;

	//-- normal grey tones
	$grey-lighter: #eeeeee;
	$grey-light: #e5e5e5; 
	$grey: #dddddd; 
	$grey-dark: #cbcbcb; 
	$grey-darker: #bbbbbb; 

	//-- dark grey tones
	$strong-grey-lighter: #979797;
	$strong-grey-light: #888888; 
	$strong-grey: #616161; 
	$strong-grey-dark: #4c4c4c; 
	$strong-grey-darker: #333333; 

// Horizontal padding left and right of grid columns
$base-gap: 15px;

$base-radius: 0px;

// Grid row max-width
$row-max-width: 1200px;

// Standard border for your design
$base-border: 1px solid $border;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bp-context: 16px;

// Breakpoints for min-width media queries (used in _responsive.scss and _grid.scss)
$breakpoints: (
    'tiny':   em(456px, $bp-context),
    'small':  em(640px, $bp-context),
    'medium': em(752px,  $bp-context),
    'large':  em(992px, $bp-context),
    'giant':  em(1168px, $bp-context),
);

 // +++++ Miscellaneous
// text-indent value when using hide-text(): "right" = positive value, "left" = negative value
$hide-text-direction: "right";

// +++++ The following settings can be left alone in most cases
// Column selector names
$column-names: "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12";
