.form-content{
	display: inline-block;
	background: rgba($white,0.7);
	// border-radius: $base-radius;
	padding: $base-gap*2;
	// overflow: hidden;
	.branding-svg{
		height: 60px;
		width: auto;
		margin-right: $base-gap;
	}
	.form-footer{
		display: flex;
		justify-content: space-between;
		align-items: center;
		float:left;
		padding: $base-gap $base-gap*2;
		margin-top: $base-gap;
		margin-bottom: -$base-gap*2;
		margin-left: -$base-gap*2;
		width: calc(100% + 60px);
		background: $primary;

	}
}
form {	
	.input-upload{		
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 0;
		white-space: nowrap;
		float: left;
		width: 100%;
		position: relative;		
		// overflow: hidden;
		border-radius: $base-radius;
		>label{
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			z-index:1;
			height: 50vh;
			background: $grey;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			font-size: 40px;
			padding: $base-gap*5;
			font-weight: 300;
			transition: all 0.25s ease;
			img{
				max-height: 100%;
				max-width: 100%;
			}
		}
		#list{
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			pointer-events: none;
			>span{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 200px;
				width: 200px;
				border-radius: $base-radius;
				overflow: hidden;
				position: relative;
				margin: $base-gap;
				background: $black;
				pointer-events: auto;
				img{
					width: auto;
					height: auto;
					max-height: 100%;
					max-width: 100%;
				}				
				>.cancel-upload{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					border: none;
					background: none;
				}
				.title{
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: flex-end;
					justify-content: center;
					background: linear-gradient(to top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%);
					transform: translateY(100%);
					transition: all 0.25s ease;
					>span{
						display: inline-block;
						width: 100%;
						height: auto;
						opacity: 0;
						color: $light;
						font-size: 16px;
						padding: $base-gap;
						transition: all 0.25s ease;
					}
				}
				&:hover{
					.title{
						transform: none;
						>span{
							opacity: 1;
						}
					}
				}
				.progress-bar{
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: all 0.25s ease 0.25s;
					.pf-circle{
						stroke-dasharray: 274.085;
						stroke-dashoffset: 274.085;
						transition: fill 0.25s ease 0.5s, stroke 0.25s ease;
						stroke: $grey;
						fill: transparent;
					}
					&.loading{
						.pf-circle{
							stroke-dashoffset: 274.085;
							animation: uploadFileDash 2s linear forwards infinite;
						}
					}
					&.loaded{
						height: 15px;
						width: 15px;
						right: 15px;
						top: 15px;
						.pf-circle{					
							stroke-dashoffset: 0;
							stroke: $success;
							fill: $success;
						}
					}
				}
			}
		}
		.upload-footer{
			pointer-events: auto;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			padding: 0 $base-gap*2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			#progress_bar{
				background: $success;
				height: 5px;
				display: block;
				width: 0%;
			}
			input[name="submit"]{
				display: inline-block;
				width: auto;
			}
		}
		>input{
			height: 50vh;
			&:checked, &:hover, &:active, &:focus {
				+label{
					background: $grey-lighter;
					span{						
						&:before{
							transform: rotate(45deg) translate(-9px, -8px);
							opacity: 1;
						}
						&:after{
							transform: rotate(-45deg) translate(8px, -9px);
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
@keyframes uploadFileDash {
	0% { stroke-dashoffset: 274.085; } 
	100% { stroke-dashoffset: -274.085; } 
}











// ##################################################

// Form input styles
%form-field {
    background: $light;
    border: 1px solid $secondary;
    font-family: $main-font;
    font-size: 1rem;
    line-height: 1.15em;
    margin: 0 0 10px 0;
    resize: none;
    padding: .8rem;
    border-radius: $base-radius;
    
    position: relative;
    width:100%;
    min-height: 46px;

    &:focus {
        background:darken($light, 2%);
    }
}

// Form styles
form {
    display: inline-block;
    width: 100%;
    float: left;

    ::-webkit-input-placeholder, :-ms-input-placeholder, ::-moz-placeholder {
        color: $medium;
    }

    fieldset {
        border: none;

        + fieldset {
            margin-top: 2rem;
        }

        > span {
            float: left;
            width: 6rem;
            margin-top: rem(5px);
        }
    }

    .form-line{
        display: flex;
        width: 100%;
        >label,>input,>button{
            &:nth-child(1){
                width: 100%;
                border-top-left-radius: $base-radius;
                border-bottom-left-radius: $base-radius;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;   
            }
            &:nth-child(2){
                &.checkbox{
                    width: auto;
                    input{
                        margin: 0;
                    }
                }            
                &.icon-only{ 
                    .icon{                        
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: $base-radius;
                        border-bottom-right-radius: $base-radius;
                    }
                }    
            }
        }
    }

    label {
        // padding: .8rem 0;
        width: 100%;
        min-height: 46px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;

        @include breakpoint(small) {
            clear: both;
            float: left;
            // padding-right:1rem;
        }

        // &.radio {
        //     clear: none;
        //     // display: inline-block;
        //     width: 100%;
        // }

        &.checkbox{
            @extend %form-field;
            display: flex;
            color: #767676;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 0 0 0.8em;
            .information{
                display: none;
            }
            &.icon-only{
                width: auto;
                display: inline-block;
                padding: 0;
                border: none;
                background: transparent;
                .information{
                    pointer-events: none;
                    display: inline-block;
                    opacity: 0;
                    position: absolute;
                    bottom: calc(100% + 10px);
                    left: 50%;
                    transform: translate(-50%, -15px);
                    width: auto;
                    background: $white;
                    color: $strong-grey;
                    white-space: nowrap;
                    padding: 10px 15px;
                    box-shadow: 0 0 7px -2px $strong-grey-darker;
                    z-index: 1;
                    transition: all 0.25s ease;
                    border-radius: $base-radius;
                    &:after{
                        content:"";
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 5px solid $strong-grey-lighter;
                        border-left: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-right: 5px solid transparent;
                        position: absolute;
                        left: 50%;
                        top: 100%;
                        transform: translateX(-50%);
                    }
                }
                .icon{
                    height: 46px;
					transition: all ease-in .1s;
                    border-radius: $base-radius;
                }
                &:hover{
                	.icon{
						background-color: $grey-dark;
                	}
                    .information{
                        transform: translateX(-50%);
                        opacity: 1;
                    }
                }
            }

            &.primary, &.success, &.info, &.warning {
                .icon{
                    &:before,&:after{
                        background: $white;
                    }
                }
            }

            &.white {
                .icon{
                    background-color: $white;
                    &:before,&:after{
                        background: $strong-grey-lighter;
                    }
                }                   
                &:hover {
                    .icon{
                        background-color: darken($white,2%);
                    }
                }
            }

            &.primary {
                .icon{
                    background-color: $primary;
                }
                &:hover {
                    .icon{
                        background-color: $primary-hover;
                    }
                }
            }
            &.success {
                .icon{
                    background-color: $success;
                }
                &:hover {
                    .icon{
                        background-color: $success-hover;
                    }
                }
            }
            &.info {
                .icon{
                    background-color: $info;
                }
                &:hover {
                    .icon{
                        background-color: $info-hover;
                    }
                }
            }
            &.warning {
                .icon{
                    background-color: $warning;
                }
                &:hover {
                    .icon{
                        background-color: $warning-hover;
                    }
                }
            }
            input{
                display: none;
                &:checked{
                    ~.icon{
                        &:before{
                            width: 7px;
                            top:25px;
                            left:15px;
                        }
                        &:after{
                            width: 12px;
                            top:23px;
                            left:19px;
                        }
                    }
                }
            }   
            .icon{
                display: block;
                width: 46px;
                min-width: 46px;
                min-height: 44.666px;
                height: 100%;
                background: $grey;
                cursor: pointer;
                position: relative;
                float: right; 
                &:before{
                    content:"";
                    display: block;
                    height:1px;
                    width:12px;
                    position:absolute;
                    top:22px;
                    left:16px;
                    background:$strong-grey-lighter;
                    transform: rotate(45deg);
                    transition: all 0.25s ease;
                }
                &:after{
                    content:"";
                    display: block;
                    height:1px;
                    width:12px;
                    position:absolute;
                    top:22px;
                    left:16px;
                    background:$strong-grey-lighter;
                    transform: rotate(-45deg);
                    transition: all 0.25s ease;
                }
            }
        }

        small {
            color: $alert;
            display: block;
            line-height: 1rem;
        }

        &.error {
            display: none!important;
        }
    }

    .radio-box{
        @extend %form-field;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        &.nowrap{
            flex-wrap: nowrap;
            .radio{
                border: none;
                min-height: 44px;
                .icon{
                    min-height: 44px;
                }
            }
        }
        .radio{
            @extend %form-field;
            display: flex;
            color: #767676;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 0 0 0.8em;
            border-top: none;
            border-left: none;
            border-right: none;
            border-color: $secondary;
            margin: 0;
            &:last-child{
                border: none;
            }
            .information{
                display: none;
            }
            &.icon-only{
                width: auto;
                display: inline-block;
                padding: 0;
                border: none;
                .information{
                    pointer-events: none;
                    display: inline-block;
                    opacity: 0;
                    position: absolute;
                    bottom: calc(100% + 10px);
                    left: 50%;
                    transform: translate(-50%, -15px);
                    width: auto;
                    background: $white;
                    padding: 10px 15px;
                    box-shadow: 0 0 7px -2px $strong-grey-darker;
                    z-index: 1;
                    transition: all 0.25s ease;
                    &:after{
                        content:"";
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 5px solid $strong-grey-lighter;
                        border-left: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-right: 5px solid transparent;
                        position: absolute;
                        left: 50%;
                        top: 100%;
                        transform: translateX(-50%);
                    }
                }
                &:hover{
                    .information{
                        transform: translateX(-50%);
                        opacity: 1;
                    }
                }
            }

            input{
                display: none;
                &:checked{
                    ~.icon{
                        &:before{
                            width: 7px;
                            top:25px;
                            left:15px;
                        }
                        &:after{
                            width: 12px;
                            top:23px;
                            left:19px;
                        }
                    }
                }
            }   
            .icon{
                display: block;
                width: 46px;
                min-width: 46px;
                min-height: 44px;
                height: 100%;
                background: $primary;
                cursor: pointer;
                position: relative;
                float: right; 
                &:before{
                    content:"";
                    display: block;
                    height:1px;
                    width:12px;
                    position:absolute;
                    top:22px;
                    left:16px;
                    background:$light;
                    transform: rotate(45deg);
                    transition: all 0.25s ease;
                }
                &:after{
                    content:"";
                    display: block;
                    height:1px;
                    width:12px;
                    position:absolute;
                    top:22px;
                    left:16px;
                    background:$light;
                    transform: rotate(-45deg);
                    transition: all 0.25s ease;
                }
            }
        }
    }


    legend {
        // background: $medium;
        color: $primary;
        font-size: rem(24px);
        width: 100%;
        padding-bottom: $base-gap;
    }

    [type="text"] {
        @extend %form-field;
    }

    [type="password"] {
        @extend %form-field;
    }

    [type="tel"] {
        @extend %form-field;
    }

    [type="email"] {
        @extend %form-field;
    }

    [type="file"] {
        @extend %form-field;
    }

    [type="submit"],[type="reset"] {
        height: 46px;
        // width: 100%;
    }

    [type="reset"]{
        display: none;
    }

    textarea {
        @extend %form-field;
        line-height: 19.2px;
    }

    select {
        @extend %form-field;
        height: 46px;
        padding: 10px 0.8rem;
    }

    [type="checkbox"] {
        float: left;
        margin: 1rem .3rem .8rem;
    }

    button {
        cursor: pointer;
        font-family: $main-font;
        width: 100%;
        font-size: 18px;
        margin: 0;
        border-radius: $base-radius;
    }

    .errorContainer {
        position: relative;
    }
    .error{
        transition: all 0.25s ease;
        &:not(:focus){
            border-color: $alert;
        }
    }

}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.specialfield {
    display: none;
}

#newsletter-info {
    border: rem(1px) solid $alert;
    padding: 1rem;
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}



.pf-overlay{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: radial-gradient(ellipse at center, rgba($light, 0.5), rgba($light, 1) 100%);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all .25s ease;
    &.pf-overlay-active{
        opacity: 1;
    }
    .pf-progress{
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: $primary;
        transition: all 0.25s ease;
        z-index: 20;
        &[style*="100"]{
            background: $grey-darker;
        }
    }
    .pf-nav-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($secondary,0);
        cursor: pointer;
        position: absolute;
        z-index: 10;
        height: 30px;
        width: 45px;
        transition: all 0.25s ease;
        span{
            pointer-events: none;
            display: inline-block;
            opacity: 0;
            position: absolute;
            top: calc(100% + 10px);
            right: 0;
            transform: translateY(15px);
            width: auto;
            color: $white;
            font-size: 10px;
            background: $strong-grey;
            white-space: nowrap;
            padding: 10px 15px;
            box-shadow: 0 0 7px -2px $strong-grey-darker;
            z-index: 1;
            transition: all 0.25s ease;
            &:after{
                content:"";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 5px solid $strong-grey;
                border-left: 5px solid transparent;
                border-top: 5px solid transparent;
                border-right: 5px solid transparent;
                position: absolute;
                right: 0;
                bottom: 100%;
                transform: translateX(-18px);
            }
        }
        &:hover{
            span{
                transform: none;
                opacity: 1;
                color: $light;
            }
        }
    }
    .pf-minimize{
        top: 0;
        right: 45px;
        &:before {
            transform: translate(-50%, 0);
            background: $strong-grey-darker;

            position: absolute;
            left: 50%;
            bottom: 11px;

            content:"";
            display: block;
            height:2px;
            min-height:2px;
            width:8px;
            transition: all 0.25s ease;
        }
        &:hover{
            background: $strong-grey;
            &:before{
                width: 10px;
                background: $light;
            }
        }
    }

    .pf-close{
        top: 0;
        right: 0;

        &:before {
            transform: rotate(45deg) translate(-4px, 3px);
            width:10px;

            background: $strong-grey-darker;
            position: absolute;
            left: 50%;
            top: 50%;

            content:"";
            display: block;
            height:2px;
            min-height:2px;

            transition: all 0.25s ease;
        }
        &:after {
            transform: rotate(-45deg) translate(-3px, -4px);
            width:10px;

            background: $strong-grey-darker;
            position: absolute;
            left: 50%;
            top: 50%;

            content:"";
            display: block;
            height:2px;
            min-height:2px;
         
            transition: all 0.25s ease;
        }

        &:hover{
            background: $warning;
            &:before{                
                transform: rotate(45deg) translate(-5px, 4px);
                width:12px;
                background: $light;
            }
            &:after {
                transform: rotate(-45deg) translate(-4px, -5px);
                width:12px;
                background: $light;
            }
        }
    }
        
    .pf-content{
        width: 752px;
        background: $light;
        // box-shadow: 0 0 5px 0 rgba($light, 0.8);
        box-shadow: 0 0 7px -2px $strong-grey-darker;
        max-height: 90vh;
        overflow: auto;
        position: relative;
        .pf-msg{
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 $base-gap*4;
            transition: padding 0.5s ease 0.5s, max-height 3.5s ease 0.5s, opacity 3.5s ease 0.5s;
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            svg{
                transition: all 0.5s ease;
                .pf-circle{
                    stroke: $grey;
                    stroke-dasharray: 274px;
                    stroke-dashoffset: 274px;
                }
                .pf-msg-icon{
                    opacity: 0;
                }
            }
            &.pf-msg-open{                
                // max-height: $base-gap*4;
                padding: $base-gap*4;
                max-height: 2000px;
                opacity: 1;
                svg{
                    .pf-circle{
                        animation: pfCircle 2s 1s ease forwards;
                        transition: stroke 2s 1s ease; 
                    }
                    .pf-msg-icon{
                        opacity: 1;
                        transition: opacity 0.25s 3s ease; 
                    }
                }
                &.pf-success{
                    svg{
                        .pf-circle{
                            stroke: $success;
                        }
                        .pf-msg-icon{
                            stroke: $success;
                        }
                    }
                }
                &.pf-error{
                    svg{
                        .pf-circle{
                            stroke: $warning;
                        }                        
                        .pf-msg-icon{
                            stroke: $warning;
                        }
                    }
                }
            }
        }
        form{
            position: relative;
            padding: $base-gap*4 $base-gap*4 146px $base-gap*4;
            &.pf-form-off{
                opacity: 0;
                transition: opacity 0.5s ease, max-height 0.5s ease, padding 1s ease 0.75s;
                padding: 0 $base-gap*4;
            }

            fieldset:not(.pf-nav){
                position: relative;
                max-height: 0;
                transition: opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease 0s;
                overflow: hidden;
                margin: 0;
                transform: translateX(100%);
                opacity: 0;
                &.pf-field-active{
                    max-height: 100vh;
                    transform: none;
                    opacity: 1;
                    transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s, max-height 1s ease 0.5s;
                }
                &.pf-field-prev{
                    max-height: 0;
                    opacity: 0;
                    transform: translateX(-100%);
                }
            }

            .pf-nav{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 20px $base-gap*4;
                background: $secondary;
                max-height: none;
            }

            [type="reset"]{
                display: block;
            }
        }
    }
}

@keyframes pfCircle{
    to  {stroke-dashoffset:0}
}