.alert-container{
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	height: auto;
	width: auto;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	opacity: 1;
	pointer-events: auto;
	transition: all 0.25s ease;
	:nth-child(1n+3){
		opacity: 0;
		pointer-events: none;
	}
}
.alert, .alert-hollow {
	line-height: rem(22px);

	h1, h2, h3, h4, h5, h6 {
		font-size: $base-font-size;
		font-weight: 600;
		margin: 0;
	}
	p {
		margin: 0;
	}
}

.alert
{
	&:not(.default) > * {color:$white}
}

.alert {
	font-size: rem(14px);
	border-radius: $base-radius;
	display: inline-block;
	padding: 7px $base-gap;
	font-weight: 400;
	position: relative;
	margin: 2px 0;
	cursor: pointer;

	&.tiny {
		padding: 5px 12px;
		font-size: rem(12px);
		font-weight: 500;
	}
	&.small {
		padding: 7px $base-gap;
		font-size: rem(14px);
		font-weight: 400;
	}
	&.medium {
		padding: 9px 16px;
		font-size: rem(16px);
		font-weight: 400;
	}
	&.large {
		padding: 12px 20px;
		font-size: rem(17px);
	}
	&.giant {
		padding: 16px 32px;
		font-size: rem(18px);
	}

	&.default {
		background-color: $grey;
		color: $strong-grey-lighter;
	}
	&.primary {
		background-color: $primary;
		color: $white;
	}
	&.success {
		background-color: $success;
		color: $white;
	}
	&.info {
		background-color: $info;
		color: $white;
	}
	&.warning {
		background-color: $warning;
		color: $white;
	}
}

.alert-hollow {
	font-size: rem(14px);
	border-radius: $base-radius;
	font-weight: 400;
	display: inline-block;
	background-color: transparent;
	border: 1px solid;
	padding: 6px $base-gap;
	position: relative;
	margin: $base-gap 0;

	&.tiny {
		padding: 4px 12px;
		font-size: rem(12px);
		font-weight: 500;
	}
	&.small {
		padding: 6px $base-gap;
		font-size: rem(14px);
		font-weight: 400;
	}
	&.medium {
		padding: 8px 16px;
		font-size: rem(16px);
		font-weight: 400;
	}
	&.large {
		padding: 11px 20px;
		font-size: rem(17px);
	}
	&.giant {
		padding: 15px 32px;
		font-size: rem(18px);
	}
	&.default {
		border-color: $grey;
		color: $strong-grey-lighter;
	}
	&.primary {
		border-color: $primary;
		&, > * {color: $primary;}
	}
	&.success {
		border-color: $success;
		&, > * {color: $success;}
	}
	&.info {
		border-color: $info;
		&, > * {color: $info;}
	}
	&.warning {
		border-color: $warning;
		&, > * {color: $warning;}
	}
}

.dismissible {
		padding-right: 36px;
		position: relative;
		
		&.tiny {
			padding-right: 36px;
			font-size: rem(12px);
			font-weight: 500;
			.close {
				top: 10px;
			}
		}
		&.small {
			padding-right: 36px;
			font-size: rem(14px);
			font-weight: 400;
			.close{
				top: 12px;
			}
		}
		&.medium {
			padding-right: 38px;
			font-size: rem(16px);
			.close{
				top: 14px;
			}
		}
		&.large {
			padding-right: 42px;
			font-size: rem(17px);
			.close {
				top: 17px;
			}
		}
		&.giant {
			padding-right: 54px;
			font-size: rem(18px);
			.close {
				top: 21px;
			}
		}

		.close {
			background-color: transparent;
			border: none;
			cursor: pointer;
			opacity: 0.3;
			margin-left: $base-gap;
			transition: all linear .2s;
			position: absolute;
			right: rem(15px);
			top: 12px;
			height: 10px;
			width: 10px;
			display: flex;
			align-items: center;
			jusitfy-content: center;

			&:hover {
				opacity: 0.5;
			}
			&:focus {
				opacity: 0.5;
				outline: none;
			}
			&:after{		
				content:'\f00d';
				font-family: FontAwesome;
				font-weight: normal;
				font-style: normal;
				text-decoration:none;
				font-size: 14px;
				line-height: 10px;
				display: inline-block;
			}
		}
	}

// end of alerts