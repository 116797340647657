
// buttons normal

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	// width: auto;
	float: left;
	padding: 7px $base-gap;
	margin: 0;
	font-size: 14px;
	text-transform: uppercase;
	height: 34px;
	min-height: 34px;
	font-weight: 400;
	line-height: $base-font-size;
	background-color: $grey-lighter;
	color: $strong-grey-darker;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-msuser-select: none;
	z-index: 0;
	border: none;
	
	user-select: none;
	text-decoration: none;
	transition: all ease .25s;
	overflow: hidden;
	+.btn,+.btn-hollow{
		margin-left: $base-gap/3;
	}
	&:after{
		content:"";
		display: block;
		height: 200px;
		width: 200px;
		background: $strong-grey-darker;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
		transform: rotate(45deg) translate(calc(100% - 15px), 50%);
		transform-origin: 100% 100%;
		transition: all 0.25s ease;
	}
	&:hover, &:active, &:focus{
		background-color: $grey-dark;
		color: $grey-lighter;
		&:after{
			transform: translate(5% , 25%);
			height: 200%;
			width: 110%;
		}
	}
	&[disabled],&.disabled{
		pointer-events: none;
		opacity: 0.6;
	}
	// size

	&.full{
		width: 100%;
	}

	&.tiny {
		padding: 5px 12px;
		font-size: 12px;
		font-weight: 500;
		height: 30px;
		min-height: 30px;
	}
	&.small {
		padding: 7px $base-gap;
		font-size: 14px;
		font-weight: 400;
		height: 34px;
		min-height: 34px;
	}
	&.medium {
		padding: 9px 16px;
		font-size: 16px;
		font-weight: 400;
		height: 38px;
		min-height: 38px;
	}
	&.large {
		padding: 12px 20px;
		font-size: 17px;
		height: 44px;
		min-height: 44px;
	}
	&.giant {
		padding: 16px 32px;
		font-size: 18px;
		height: 52px;
		min-height: 52px;
	}
	&.full{
		width: 100%;
	}
	
	// Color

	&.default {
		background-color: $grey;
		color: $strong-grey-lighter;		
		&:hover {
			background-color: $grey-dark;
		}
	}

	&.primary, &.success, &.info, &.warning {
		color: $white;
	}

	&.white {
		background-color: $white;
		color: $strong-grey-darker;

		&:hover {
			color: $grey-lighter;
		}
	}

	&.dark {
		background-color: $strong-grey-darker;
		color: $grey-lighter;
		&:after{
			background: $grey-lighter;
		}

		&:hover {
			background-color: $strong-grey;
			color: $strong-grey-darker;
		}
	}

	&.primary {
		background-color: $primary;
		&:after{
			background: $primary-hover;
		}

		&:hover {
			background-color: $primary-hover;
		}
	}
	&.success {
		background-color: $success;
		&:after{
			background: $success-hover;
		}

		&:hover {
			background-color: $success-hover;
		}
	}
	&.info {
		background-color: $info;
		&:after{
			background: $info-hover;
		}

		&:hover {
			background-color: $info-hover;
		}
	}
	&.warning {
		background-color: $warning;
		&:after{
			background: $warning-hover;
		}

		&:hover {
			background-color: $warning-hover;
		}
	}
	&:focus{
		color: $white;
	}
	&.icon-only{	
		padding: 7px;	
		height: 34px;
		width: 34px;
		&:after{
			display: none;
		}
		&:before, i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;
		}
		&.tiny {
			padding: 5px;
			height: 30px;
			width: 30px;
		}
		&.small {
			padding: 7px;
			height: 34px;
			width: 34px;
		}
		&.medium {
			padding: 9px;
			height: 38px;
			width: 38px;
		}
		&.large {
			padding: 12px;
			height: 44px;
			width: 44px;
		}
		&.giant {
			padding: 16px;
			height: 52px;
			width: 52px;
		}
	}
}

// end buttons

// button hollow

.btn-hollow {
	display: inline-block;
	padding: 7px $base-gap;
	font-size: 14px;
	font-weight: 400;
	height: 34px;
	margin: 0;
	line-height: $base-font-size;
	border: 2px solid $strong-grey-darker;
	color: $strong-grey-darker;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	position: relative;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	transition: all linear .25s;
	background-color: transparent;
	// border-radius: $base-radius;
	position: relative;
	z-index: 0;
	overflow: hidden;
	+.btn,+.btn-hollow{
		margin-left: $base-gap/3;
	}
	
	&:after{
		content:"";
		display: block;
		height: 200px;
		width: 200px;
		background: $strong-grey-darker;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
		transform: rotate(45deg) translate(230px, 0%);
		transition: all 0.25s ease;
	}

	&:hover, &:active, &:focus{
		// background: $strong-grey-lighter;
		color: $light;
		&:after{
			transform: none;
			height: 100%;
			width: 100%;
		}
	}

	&[disabled],&.disabled{
		pointer-events: none;
		opacity: 0.6;
	}

	// size

	&.tiny {
		padding: 5px 12px;
		font-size: 12px;
		font-weight: 500;
		height: 30px;
	}
	&.small {
		padding: 7px $base-gap;
		font-size: 14px;
		font-weight: 400;
		height: 34px;
	}
	&.medium {
		padding: 9px 16px;
		font-size: 16px;
		font-weight: 400;
		height: 38px;
	}
	&.large {
		padding: 12px 20px;
		font-size: 17px;
		height: 44px;
	}
	&.giant {
		padding: 16px 32px;
		font-size: 18px;
		height: 52px;
	}
	&.full{
		width: 100%;
	}

	// color

	&.default {
		&:hover, &:active, &:focus{
			background: $strong-grey-lighter;
			color: $light;
		}
	}
	
	&.white {
		color: $white;
        border-color: $white;
        &:after{
        	background: $white;
        }
		
		&:hover, &:active, &:focus{
			color: $strong-grey-darker
		}
	}
	
	&.primary {
		color: $primary;
        border-color: $primary;
        &:after{
        	background: $primary;
        }
		
		&:hover, &:active, &:focus{
			color: $light;
		    border-color: $primary-hover;
	        &:after{
	        	background: $primary-hover;
	        }
		}
	}
	&.success {
		color: $success;
        border-color: $success;
        &:after{
        	background: $success;
        }
		
		&:hover, &:active, &:focus{
			color: $light;
		    border-color: $success-hover;
	        &:after{
	        	background: $success-hover;
	        }
		}
	}
	&.info {
		color: $info;
        border-color: $info;
        &:after{
        	background: $info;
        }
		
		&:hover, &:active, &:focus{
			color: $light;
		    border-color: $info-hover;
	        &:after{
	        	background: $info-hover;
	        }
		}
	}
	&.warning {
		color: $warning;
        border-color: $warning;
        &:after{
        	background: $warning;
        }
		
		&:hover, &:active, &:focus{
			color: $light;
		    border-color: $warning-hover;
	        &:after{
	        	background: $warning-hover;
	        }
		}
	}
	&.icon-only{	
		padding: 7px;
		height: 34px;
		width: 34px;
		&:hover{
			// &:after, &:before{
			// 	height: 15px;
			// 	width: 15px;
			// }
		}
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&.tiny {
			padding: 5px;
			height: 30px;
			width: 30px;
		}
		&.small {
			padding: 7px;
			height: 34px;
			width: 34px;
		}
		&.medium {
			padding: 9px;
			height: 38px;
			width: 38px;
		}
		&.large {
			padding: 12px;
			height: 44px;
			width: 44px;
		}
		&.giant {
			padding: 16px;
			height: 52px;
			width: 52px;
		}
	}
}


// Select btn
.btn-select{
	width: 150px;
	height: 34px;
	position: relative;
	display: inline-block;
	overflow: hidden;
	float: left;
	padding: 0;
	&:after{
		content: '\f0d7';
		font-family: 'Font Awesome 5 Free';
		font-weight: 400;
	   	font-style: normal;
	   	margin: 0;
	   	font-size: 14px;
	   	display: flex; 
	   	justify-content: center;
		align-items: center;
	   	text-decoration:none;
	   	position: absolute;
	   	right: 0;
	   	top: 0;
	   	background: $strong-grey;
	   	width: 30px;
	   	height: 100%;
	   	z-index: 1;
	   	border-left: 1px solid $grey-darker;
	   	color: $grey-darker;
	   	pointer-events: none;
	   	transition: all 0.25s ease;
	}

	&.tiny {
		padding: 5px;
		height: 30px;
	}
	&.small {
		padding: 7px;
		height: 34px;
	}
	&.medium {
		padding: 9px;
		height: 38px;
	}
	&.large {
		padding: 12px;
		height: 44px;
	}
	&.giant {
		padding: 16px;
		height: 52px;
	}
	select{
		width: 100%;
		height: 100%;
		display: inline-block;
		padding: 0 0 0 $base-gap;
		border: none;
	   	transition: all 0.25s ease;
		option{
			font-size: 14px;
			width: 100%;
			padding: 8px 11px;
			// background: $strong-grey;
			outline-color: $light;
	   		transition: all 0.25s ease;
			&:hover, &:checked{
				background: $primary repeat url(data:image/gif;base64,R0lGODlhAQABAIAAAOxfXQAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==);
			}
			&.active{
				color: $grey-darker;
				box-shadow: none;
				pointer-events: none;
			}
		}
	}
	&:hover{
		&:after{
			color: $light;
		}
		select{
			background: $light;
			color: $grey-darker;
		}
	}
}
// end buttons hollow