* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::selection, ::-moz-selection {
    background: $medium;
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
    background: $light;
    color: $dark;
    font-family: $main-font;
    font-size: 100% * ($base-font-size / 16px);
    line-height: rem($base-line-height);

    /* disable text resize in landscape. e.g. on iphone */
    text-size-adjust: none;
}

body {
    overflow-x: hidden;
    padding: 0;
    background: $white;
    @include breakpoint(large){
        padding: 0 $base-gap*2;
    }
    // Debug mode
    &.the-customer-page #log_hider {
        display: none;
    }

    &.ie-8 #pageloader:after {
        display: none;
    }
}

/**
 * Headlines
 */
.topic {
    @extend %optimized-rendering;
    font-family: $display-font;
    font-size: rem(23px);
    line-height: rem(32px);
    color: $strong-grey-darker;
    text-transform: uppercase;
    margin-bottom: $base-gap*2;
    font-weight: 600;
    @include breakpoint(medium){
        font-size: rem($h1-size);
        line-height: rem(44px);
    }
    span{
        color: $strong-grey;
        font-size: 20px;
        font-weight: 600;
        display: inline-block;
        width: 100%;
        @include breakpoint(medium){
            font-size: rem(27px);
        }
    }
}
h1,.h1 {
    @extend %optimized-rendering;
    font-family: $display-font;
    font-size: rem(25px);
    line-height: rem(32px);
    color: $strong-grey-darker;
    text-transform: uppercase;
    margin-bottom: $base-gap*2;
    font-weight: 600;
    @include breakpoint(medium){
        font-size: rem($h1-size);
        line-height: rem(44px);
    }
    span{
        color: $strong-grey;
        font-size: 22px;
        font-weight: 600;
        display: inline-block;
        width: 100%;
        @include breakpoint(medium){
            font-size: rem(27px);
        }
    }
}

h2,.h2 {
    @extend %optimized-rendering;
    font-size: rem(22px);
    line-height: 1.3em;
    font-weight: 600;
    padding-bottom: $base-gap;
    margin-bottom: $base-gap;
    color: $strong-grey-darker;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    @include breakpoint(small){
        font-size: rem($h2-size);
    }
    span{
        color: $strong-grey;
        font-size: 18px;
        display: inline-block;
        width: 100%;
        
        @include breakpoint(medium){
            font-size: rem(24px);
        }
    }
}

h3 {
    @extend %optimized-rendering;
    font-size: rem($h3-size);
    font-weight: 400;
    margin: 1rem 0;
    text-transform: uppercase;
}

h4 {
    @extend %optimized-rendering;
    font-size: rem($h4-size);
}

h5 {
    @extend %optimized-rendering;
    font-size: rem($h5-size);
}

h6 {
    @extend %optimized-rendering;
    font-size: rem($h6-size);
}

/**
 * Links
 */
a {
    color:$primary;
    font-weight: 400;
    text-decoration:none; 
    transition: all 0.25s ease;  
    &:focus, &:hover, &:active { 
        color: $secondary;  
    }

    img {
        border: none;
    }
}

.text-align-center{
    text-align: center;
}

/**
 * Images
 */
img {
    vertical-align:bottom;
    max-width: 100%;
    &.full{
        width: 100%;
    }
}

.base-gap-top{
    margin-top: $base-gap;
}
.base-gap-left{
    margin-left: $base-gap;
}
.base-gap-bottom{
    margin-bottom: $base-gap;
}
.base-gap-right{
    margin-right: $base-gap;
}

.rs-base-gap-top{
    margin-top: $base-gap;
    @include breakpoint(medium){
        margin-top: 0;
    }
}
.rs-base-gap-bottom{
    margin-bottom: $base-gap;
    @include breakpoint(medium){
        margin-bottom: 0;
    }
}
.rs-base-gap-left{
    margin-left: $base-gap;
    @include breakpoint(medium){
        margin-left: 0;
    }
}
.rs-base-gap-right{
    margin-right: $base-gap;
    @include breakpoint(medium){
        margin-right: 0;
    }
}

body{
    a[data-rel*="lightbox"]{
        position:relative;
        display: inline-block;
        overflow:hidden;  
        width:100%;
        height: 200px;
        text-align:center;
        margin-top: 1rem;
        img {
            filter: blur(0) grayscale(0);
            -webkit-filter: blur(0) grayscale(0);
            -moz-filter: blur(0) grayscale(0);
            -ms-filter: blur(0) grayscale(0);
            -o-filter: blur(0) grayscale(0);
            transition: all 0.25s ease;
        }
        &:before{
            content:"";
            display: block;
            width: 100%;
            height: 100%;
            background:$secondary;
            opacity:0.5;
            position:absolute;
            top:100%;
            left:0;
            z-index: 1;
            border:none;
            transition: all 0.25s ease;
        }
        &:after{
            display: none;
        }
        &:hover{                
            // border: 1px solid $primary;
            img{
                filter: blur(2px) grayscale(100%);
                -webkit-filter: blur(2px) grayscale(100%);
                -moz-filter: blur(2px) grayscale(100%);
                -ms-filter: blur(2px) grayscale(100%);
                -o-filter: blur(2px) grayscale(100%);
            }            
            &:before{
                top:0%;
                background:$primary;
            }
        }
    }  
}
.accHandler{
    +div{
        img{
            width: 100%;
            height: auto;
        }
    }
}
.content, .footer-main, .sidebar{
    a:not(.btn){
        color:#999;
        text-decoration:none;
        position:relative;
        z-index:1;
        hyphens:auto;
        display: inline-block;
        &:after{
            content:"";
            display: block;
            background: $primary;
            border: 1px solid $primary;
            height: 100%;
            left: -3px;
            position: absolute;
            padding:2px;
            top: -3px;
            width: 100%;
            opacity:0;
            z-index: -1;
            transition: all 0.25s ease;
        }
        &:focus, &:hover, &:active { 
            color: $light;       
            &:after{
                opacity:1;
            }
        }
    }
}

/**
 * Paragraphs
 */
p {
    margin-bottom:1.2em;
    hyphens: auto;
    font-size:1.125rem;
    font-weight:300;
    color:$dark;
}
strong{
    font-weight: 600;
}
hr{
    height: rem(11px);
    background: url(/images/hr.png) no-repeat;
    border: none;
    clear: both;
    margin:$base-gap 0 $base-gap*2;
}


/**
 * Lists
 */
ul, ol, dl {
    margin-bottom: 1rem;
    hyphens: auto;
}

ul {
    li {
        padding: .5rem 0;
    }
    ul {
        margin-left: 1rem;
        margin-bottom: 0;
    }
    list-style: none;
}

main{
    ul{
        li{
            position:relative;
            padding: 0.935rem 0.7rem 0.935rem 2rem;
            font-size: rem(18px);
            &:nth-child(2n){
                background:darken($light, 5%);
            }
            &:before{
                content:"";
                display: block;
                height:2px;
                width:7px;
                position:absolute;
                top:25px;
                left:13px;
                background:$primary;
                transform: rotate(45deg);
                transition: all 0.25s ease;
            }
            &:after{
                content:"";
                display: block;
                height:2px;
                width:7px;
                position:absolute;
                top:29px;
                left:13px;
                background:$primary;
                transform: rotate(-45deg);
                transition: all 0.25s ease;
            }
            &:hover{
                &:before{
                    left: 11px;
                    top: 29px;
                    width: 5px;
                }
                &:after{                    
                    left: 13px;
                    top: 27px;
                    width: 9px;
                }
            }
        }
    }
}